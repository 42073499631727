import store from 'store';
import api from 'config/api';
import { manageAPICall, manageSourceBoosterAPICall } from './CommonCrudAction';
// global search
export const getGlobalSearchData = async (data: object, callback: any) => {
  manageAPICall(api.globalSearch.url, api.globalSearch.method, data, callback);
};
// ATS Dashboard ..........................................
export const getATSDashboardCounter = async (data: object, callback: any) => {
  manageAPICall(
    api.atsDashboardCounter.url,
    api.atsDashboardCounter.method,
    data,
    callback
  );
};
export const getATSDashboardHiringPipeline = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.atsDashboardHiringPipeline.url,
    api.atsDashboardHiringPipeline.method,
    data,
    callback
  );
};
export const getATSDashboardApplicatioNChartData = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.atsDashboardApplicationChart.url,
    api.atsDashboardApplicationChart.method,
    data,
    callback
  );
};
export const getATSDashboardCandidateStageData = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.atsDashboardCandidateStages.url,
    api.atsDashboardCandidateStages.method,
    data,
    callback
  );
};
export const getATSDashboardRateMeterData = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.atsDashboardRateMeter.url,
    api.atsDashboardRateMeter.method,
    data,
    callback
  );
};
// crm Dashboard
export const getCRMDashboardCounter = async (data: object, callback: any) => {
  manageAPICall(
    api.crmDashboardCounter.url,
    api.crmDashboardCounter.method,
    data,
    callback
  );
};
export const getLeadDevelopmentYearlyForecastData = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.leadDevelopmentYearlyForecastData.url,
    api.leadDevelopmentYearlyForecastData.method,
    data,
    callback
  );
};
export const getCrmSalesRevenueChartData = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.crmSalesRevenueChart.url,
    api.crmSalesRevenueChart.method,
    data,
    callback
  );
};
