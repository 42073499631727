import { DEFAULT_AWS_IMAGE } from 'config/app.config';
import { capitalizeWord, encryptText, placeParamsToURL } from './CommonHelper';
import { capitalize } from './utils';
import FormalInterviewSvg from 'components/_trackivo/common/Icons/SVGIcons/FormalInterviewSvg';
import { t } from 'i18next';
import VideoInterviewSvg from 'components/_trackivo/common/Icons/SVGIcons/VideoInterviewSvg';
import TelephonicInterviewSvg from 'components/_trackivo/common/Icons/SVGIcons/TelephonicInterviewSvg';
import { mainRoutes } from 'config/routing';
import {
  ZoomIcon,
  GoogleIcon,
  MailIcon
} from 'components/_trackivo/common/Icons';
import { permissionSlugs } from './PermissionHelper';
export const USER_SLUG = 'user';
export const CANDIDATE_SLUG = 'candidate';
export const CLIENT_SLUG = 'client';
export const ACCOUNT_SLUG = 'account';
export const LEAD_SLUG = 'lead';
export const LEAD_DEVELOPMENT_SLUG = 'development';
export const PROPOSAL_SLUG = 'proposal';
export const DEAL_SLUG = 'deal';
export const JOB_OPENING_SLUG = 'job_opening';
export const CANDIDATE_SUBMISSION_SLUG = 'candidate_submission';
export const INTERVIEW_SLUG = 'interview';
export const moduleSlugs = {
  company: 'company',
  companyBranch: 'branch'
};
export const serviceSlugs = {
  ats: 'ats',
  crm: 'crm'
};
// link account
export const ZOOM_SLUG = 'zoom';
export const GOOGLE_SLUG = 'google';
export const linkAccountTypes = [
  {
    type: ZOOM_SLUG,
    name: t('zoom'),
    icon: ZoomIcon,
    message:
      'Configure Account to create Zoom meetings and invite users to join.'
  },
  {
    type: GOOGLE_SLUG,
    name: t('google'),
    icon: GoogleIcon,
    message:
      'Configure Account to create Google meetings and invite users to join.'
  }
];
// email
export const USER_EMAIL_TEMPLATE_TYPE_SLUG = 'user';
export const SYSTEM_EMAIL_TEMPLATE_TYPE_SLUG = 'system';
export const CUSTOM_EMAIL_SLUG = 'custom';
export const emailSettingsTypes = [
  {
    type: CUSTOM_EMAIL_SLUG,
    name: t('custom'),
    icon: MailIcon,
    message: 'Configure SMTP Account credentials to send mail'
  },
  {
    type: GOOGLE_SLUG,
    name: t('google'),
    icon: GoogleIcon,
    message:
      'Configure Account to create Google meetings and invite users to join.'
  }
];
export const getEmailSettingTypeObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = emailSettingsTypes.filter(function (item) {
    return item.type == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const EMAIL_MAILER_LIST = [
  { slug: 'smtp', name: t('smtp') },
  { slug: 'sendmail', name: t('sendmail') },
  { slug: 'mailgun', name: t('mailgun') },
  { slug: 'ses', name: t('ses') }
];
export const getEmailMailerObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = EMAIL_MAILER_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const EMAIL_ENCRYPTION_LIST = [
  { slug: 'ssl', name: t('ssl') },
  { slug: 'tls', name: t('tls') }
];
export const getEmailEncryptionObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = EMAIL_ENCRYPTION_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// email status
export const EMAIL_STATUS_LIST = [
  { slug: 'draft', name: t('draft') },
  { slug: 'sent', name: t('sent') },
  { slug: 'delivered', name: t('delivered') },
  { slug: 'opened', name: t('opened') },
  { slug: 'failed', name: t('failed') }
];
export const getEmailStatusObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = EMAIL_STATUS_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// email template
export const EMAIL_TEMPLATE_MODULE_LIST = [
  { slug: 'employee', name: t('employee') },
  { slug: 'candidate ', name: t('candidate ') },
  { slug: 'branch ', name: t('branch ') },
  { slug: 'interview ', name: t('interview ') }
];
// email module and submodule
export const SUBMODULE_TASK_STATUS_CHANGE_SLUG = 'task.statusChanged';
export const SUBMODULE_TASK_RESCHEDULED_SLUG = 'task.rescheduled';
export const SUBMODULE_TASK_CREATE_SLUG = 'task.create';
export const SYSTEM_EMAIL_TEMPLATE_MODULE_LIST = [
  {
    name: 'Task',
    slug: 'task',
    child: [
      {
        name: 'Create',
        slug: SUBMODULE_TASK_CREATE_SLUG,
        hasDynamicSelectionList: false
      },
      {
        name: 'Rescheduled',
        slug: SUBMODULE_TASK_RESCHEDULED_SLUG,
        hasDynamicSelectionList: false
      },
      {
        name: 'Status Changed',
        fieldName: 'Task status',
        slug: SUBMODULE_TASK_STATUS_CHANGE_SLUG,
        hasDynamicSelectionList: true
      }
    ]
  },
  {
    name: 'Candidate',
    slug: 'candidate'
  }
];

// attendance Status.....................
export const ATTENDANCE_CHECKIN_OUT_LATE_COMING = 'late_coming';
export const ATTENDANCE_CHECKIN_OUT_EARLY_GOING = 'early_going';
export const ATTENDANCE_CHECKIN_OUT_STATUS_LIST = [
  { slug: ATTENDANCE_CHECKIN_OUT_LATE_COMING, name: t('late_coming') },
  { slug: ATTENDANCE_CHECKIN_OUT_EARLY_GOING, name: t('early_going') }
];
export const ATTENDANCE_ABSENT_SLUG = 'A';
export const ATTENDANCE_STATUS_LIST = [
  { slug: 'LH', name: 'Less Hours' },
  { slug: ATTENDANCE_ABSENT_SLUG, name: 'Absent' },
  { slug: 'P', name: 'Present' },
  { slug: 'HD', name: 'Half Day' },
  { slug: 'NA', name: 'Not Applicable' }
];
export const getAttendanceStatusObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = ATTENDANCE_STATUS_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// Job Duration Type.......................
export const JOB_DURATION_TYPE_LIST = [
  { slug: 'day', name: t('day') },
  { slug: 'week', name: t('week') },
  { slug: 'month', name: t('month') },
  { slug: 'year', name: t('year') }
];
// leave tracker.......................
export const LEAVE_STATUS_PENDING_SLUG = 'pending';
export const LEAVE_STATUS_CANCELLED_SLUG = 'cancelled';
export const LEAVE_HALF_TYPE_LIST = [
  { slug: 'first', name: t('first') },
  { slug: 'second', name: t('second') }
];
// calendar code start...................
export const GOOGLE_CALENDAR_ACCESS_SCOPE_LIST = [
  'https://www.googleapis.com/auth/calendar',
  // 'https://mail.google.com/'
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile'
];
export const GOOGLE_ACCESS_SCOPE_LIST = [
  'https://mail.google.com/',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile'
];
export const CALENDER_TYPE_INTERVIEW_SLUG = 'interview';
export const CALENDER_TYPE_EVENT_SLUG = 'event';
export const CALENDER_TYPE_TASK_SLUG = 'task';
export const CALENDER_TYPE_CALL_LOG_SLUG = 'log';
export const CALENDER_TYPE_LIST = [
  {
    slug: CALENDER_TYPE_EVENT_SLUG,
    name: t('event'),
    permission: permissionSlugs.create_event
  },
  {
    slug: CALENDER_TYPE_TASK_SLUG,
    name: t('task'),
    permission: permissionSlugs.create_task
  }
];
export const TASK_STATUS_COMPLETED_SLUG = 'completed';
export const getCalendarTypeObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = CALENDER_TYPE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const CALENDER_TASK_EVENT_PRIORITY_LIST = [
  { slug: 'high', name: t('high'), color_code: '#ffa500' },
  { slug: 'low', name: t('low'), color_code: '#357de8' },
  { slug: 'normal', name: t('normal'), color_code: '#800080' }
];
export const getCalendarTaskEventPriorityObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = CALENDER_TASK_EVENT_PRIORITY_LIST.filter(
    function (item) {
      return item.slug == slug;
    }
  );
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const CALENDER_TASK_EVENT_STATUS_LIST = [
  { slug: 'not_started', name: t('not_started'), color_code: '#800080' },
  { slug: 'in_progress', name: t('in_progress'), color_code: '#ffa500' },
  { slug: 'completed', name: t('completed'), color_code: '#32cd32' }
];
export const getCalendarStatusObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.name}`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getCalendarTaskEventStatusObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = CALENDER_TASK_EVENT_STATUS_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getEventRelatedToPayload = (rowPayload: any) => {
  let returnPayloadObj = null;
  if (rowPayload.job_opening && rowPayload.job_opening.length > 0) {
    returnPayloadObj = {
      type: JOB_OPENING_SLUG,
      payload: rowPayload.job_opening[0],
      title: rowPayload.job_opening[0].job_title,
      redirect_link: ''
      // redirect_link: placeParamsToURL(mainRoutes.jobOpeningDetail.path, {
      //   id: encryptText(rowPayload.job_opening[0].id)
      // })
    };
  } else if (rowPayload.lead && rowPayload.lead.length > 0) {
    returnPayloadObj = {
      type: LEAD_SLUG,
      payload: rowPayload.lead[0],
      title: capitalizeWord(
        rowPayload.lead[0].first_name + ' ' + rowPayload.lead[0].last_name
      ),
      redirect_link: ''
      // redirect_link: placeParamsToURL(
      //   mainRoutes.leadAndDevelopmentDetail.path,
      //   {
      //     type: LEAD_SLUG,
      //     id: encryptText(rowPayload.lead[0].id)
      //   }
      // )
    };
  } else if (rowPayload.candidate && rowPayload.candidate.length > 0) {
    returnPayloadObj = {
      type: CANDIDATE_SLUG,
      payload: rowPayload.candidate[0],
      title: capitalizeWord(
        rowPayload.candidate[0].first_name +
          ' ' +
          rowPayload.candidate[0].last_name
      ),
      redirect_link: ''
      // redirect_link: placeParamsToURL(mainRoutes.candidateDetail.path, {
      //   id: encryptText(rowPayload.candidate[0].id)
      // })
    };
  }
  // else if (rowPayload.interview.length > 0) {
  //   returnPayloadObj = {
  //     type: INTERVIEW_SLUG,
  //     payload: rowPayload.interview[0],
  //     title: capitalizeWord(
  //       rowPayload.candidate[0].first_name +
  //         ' ' +
  //         rowPayload.candidate[0].last_name
  //     ),
  //     redirect_link: placeParamsToURL(mainRoutes.candidateDetail.path, {
  //       id: encryptText(rowPayload.candidate[0].id)
  //     })
  //   };
  // }
  return returnPayloadObj;
};
export const getExistingDataForEventRelatedTo = (existingDetails: any) => {
  let existingRelatedToType = '';
  let existingRelatedTo = null;
  if (existingDetails.job_opening.length > 0) {
    existingRelatedToType = JOB_OPENING_SLUG;
    existingRelatedTo = getJobOpeningObj(existingDetails.job_opening[0]);
  } else if (existingDetails.candidate.length > 0) {
    existingRelatedToType = CANDIDATE_SLUG;
    existingRelatedTo = getCandidateObj(existingDetails.candidate[0]);
  } else if (existingDetails.lead.length > 0) {
    existingRelatedToType = LEAD_SLUG;
    existingRelatedTo = getLeadObj(existingDetails.lead[0]);
  }
  return {
    existingRelatedToType: existingRelatedToType,
    existingRelatedTo: existingRelatedTo
  };
};
export const WILLING_TO_RELOCATE_LIST = [
  { slug: true, name: t('yes') },
  { slug: false, name: t('no') }
];
export const WORK_ABROAD_LIST = [
  { slug: true, name: t('yes') },
  { slug: false, name: t('no') }
];
export const NOTIFICATION_DURATION_TYPE_LIST = [
  { slug: 'minutes', name: t('minutes') },
  { slug: 'hours', name: t('hours') },
  { slug: 'days', name: t('days') },
  { slug: 'weeks', name: t('weeks') }
];
export const REPEAT_LIST = [
  { slug: 'daily', name: t('daily') },
  { slug: 'weekly', name: t('weekly') },
  { slug: 'monthly', name: t('monthly') },
  { slug: 'yearly', name: t('yearly') }
];
export const getRepeatObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = REPEAT_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const NOTIFICATION_TYPE_EMAIL_SLUG = 'email';
export const NOTIFICATION_TYPE_PUSH_SLUG = 'push';
export const NOTIFICATION_TYPE_LIST = [
  { slug: NOTIFICATION_TYPE_EMAIL_SLUG, name: t('email') }
  // { slug: NOTIFICATION_TYPE_PUSH_SLUG, name: t('push') }
];
export const CALENDER_SCHEDULE_TYPE_LIST = [
  { slug: 'general', name: t('general') },
  { slug: 'business', name: t('business') },
  { slug: 'personal', name: t('personal') },
  { slug: 'meeting', name: t('meeting') },
  { slug: 'birthday', name: t('birthday') },
  { slug: 'report', name: t('report') },
  { slug: 'must_attend', name: t('must_attend') }
];
export const getCalendarLabelObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.name}`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getCalendarScheduleTypeObjBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = CALENDER_SCHEDULE_TYPE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// calendar code end...................
// get holiday obj
export const getHolidayObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.title
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get timezone obj
export const getTimezoneObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.timezone
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get industry obj
export const getIndustryObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get country obj
export const getCountryObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name),
    code: element.code
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get state obj
export const getStateObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get city obj
export const getCityObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get currency obj
export const getCurrencyObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.code} (${element.symbol})`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get job title obj
export const getJobTitleObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.name}`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get job opening obj
export const getJobOpeningObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.job_title}`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get job opening status obj
export const getJobOpeningStatusObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.name}`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getPayTypeObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.name}`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getLeaveTypeObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.name}`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getLeaveStatusObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: `${element.name}`,
    slug: `${element.slug}`
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// // get blood group list and  obj...start.....
export const BLOOD_GROUP_LIST = [
  { id: 'A+', name: 'A+' },
  { id: 'A-', name: 'A-' },
  { id: 'B+', name: 'B+' },
  { id: 'B-', name: 'B-' },
  { id: 'AB+', name: 'A+' },
  { id: 'AB-+', name: 'B+' },
  { id: 'O+', name: 'O+' },
  { id: 'O-', name: 'O-' }
];
export const getBloodGroupObjById = (id: any) => {
  let selectedSlug = null;
  const filteredData = BLOOD_GROUP_LIST.filter(function (item) {
    return item.id == id;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// // get blood group list and  obj...end.....
// work place type start......
export const ON_SITE_SLUG = 'on-site';
export const REMOTE_SLUG = 'remote';
export const HYBRID_SLUG = 'hybrid';
export const WORK_PLACE_TYPE_LIST = [
  {
    slug: ON_SITE_SLUG,
    name: t('on-site')
  },
  {
    slug: REMOTE_SLUG,
    name: t('remote')
  },
  {
    slug: HYBRID_SLUG,
    name: t('hybrid')
  }
];
export const getWorkPlaceTypeBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = WORK_PLACE_TYPE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// work place type end......
// joining availability start......
export const JOINING_AVAILABILITY_LIST = [
  {
    slug: 'immediate',
    name: t('immediate')
  },
  {
    slug: 'within-15-days',
    name: t('within-15-days')
  },
  {
    slug: 'within-1-month',
    name: t('within-1-month')
  },
  {
    slug: 'within-2-months',
    name: t('within-2-months')
  },
  {
    slug: 'within-3-months',
    name: t('within-3-months')
  },
  {
    slug: 'within-6-months',
    name: t('within-6-months')
  }
];
export const getJoiningAvailabilityBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = JOINING_AVAILABILITY_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// joining availability end......
export const FORMAL_INTERVIEW_SLUG = 'formal';
export const VIDEO_INTERVIEW_SLUG = 'video';
export const TELEPHONIC_INTERVIEW_SLUG = 'telephonic';
export const INTERVIEW_PROVIDER_LIST = [
  {
    slug: 'google_meet',
    name: t('google_meet')
  },
  {
    slug: 'zoom',
    name: t('zoom')
  }
];
export const INTERVIEW_TYPE_LIST = [
  {
    slug: FORMAL_INTERVIEW_SLUG,
    name: t('formal_interview'),
    icon: FormalInterviewSvg
  },
  {
    slug: VIDEO_INTERVIEW_SLUG,
    name: t('video_interview'),
    icon: VideoInterviewSvg
  },
  {
    slug: TELEPHONIC_INTERVIEW_SLUG,
    name: t('telephonic_interview'),
    icon: TelephonicInterviewSvg
  }
];
export const getInterviewTypeBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = INTERVIEW_TYPE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const INTERVIEW_DURATION_LIST = [
  {
    id: 30,
    name: 30 + ' ' + t('minutes')
  },
  {
    id: 60,
    name: 60 + ' ' + t('minutes')
  },
  {
    id: 90,
    name: 90 + ' ' + t('minutes')
  }
];
export const getInterviewDurationById = (id: any) => {
  let selectedSlug = null;
  const filteredData = INTERVIEW_DURATION_LIST.filter(function (item) {
    return item.id == id;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getInterviewLevelObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const STATUS_LIST = [
  { id: true, name: 'Active' },
  { id: false, name: 'Inactive' }
];
export const getStatusObjById = (id: any) => {
  let selectedSlug = null;
  const filteredData = STATUS_LIST.filter(function (item) {
    return item.id == id;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getStatusNameById = (id: any) => {
  let selectedSlug = '-';
  const filteredData = getStatusObjById(id);
  if (filteredData) {
    selectedSlug = filteredData.name;
  }
  return selectedSlug;
};
export const GENDER_LIST = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' }
];
export const getGenderObjById = (id: any) => {
  let selectedSlug = null;
  const filteredData = GENDER_LIST.filter(function (item) {
    return item.id == id;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getSkillObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getShiftObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getRoleObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    // obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
    obj = { ...obj, ...{ isDisabled: element.status ? false : false } };
  }
  return obj;
};
export const getEmployeeTypeObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getCandidateObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    mobile: element.mobile,
    mobile_code: element.mobile_code,
    name: capitalizeWord(element.first_name + ' ' + element.last_name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getClientObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getBranchObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getModuleObj = (element: any, setDisabled = false) => {
  const obj = {
    id: element,
    name: capitalizeWord(element)
  };
  if (setDisabled) {
    // obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getVisaTypeObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getReferenceObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};

export const getDepartmentObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getAttachmentCategoryObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getEmployeeObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.user.id,
    name: capitalizeWord(element.user.first_name + ' ' + element.user.last_name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getUserObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.first_name + ' ' + element.last_name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const getProfilePic = (userProfile: string) => {
  if (userProfile) {
    return userProfile;
  }
  return DEFAULT_AWS_IMAGE.profile;
};
export const ACTIVE_CANDIDATE_STATUS_SLUG = 'active';
export const getCandidateStatusObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// for submitted client
export const getInterviewStatusObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get service category obj
export const getServiceCategoryObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// get service obj
export const getCompanyServiceObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const DEFAULT_CURRENCY_TEXT = 'USD';
export const CURRENCY_NAME_LIST = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'FOK',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KID',
  'KMF',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLE',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STN',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TVD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XDR',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL'
];
export const currencySymbols: any = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: 'A$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: 'Bs.',
  BRL: 'R$',
  BSD: '$',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BZD: '$',
  CAD: '$',
  CDF: 'FC',
  CHF: 'Fr.',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUP: '$',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: '$',
  DZD: 'دج',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  FOK: 'kr',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHS: '₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: 'HK$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: '$',
  JOD: 'د.ا',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KID: '$',
  KMF: 'CF',
  KRW: '₩',
  KWD: 'د.ك',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: 'Rs',
  LRD: '$',
  LSL: 'L',
  LYD: 'ل.د',
  MAD: 'DH',
  MDL: 'L',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: 'ر.ع.',
  PAB: 'B/.',
  PEN: 'S/',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'ر.ق',
  RON: 'lei',
  RSD: 'дин',
  RUB: '₽',
  RWF: 'FRw',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: '£',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLE: 'Le',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STN: 'Db',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'T',
  XAF: 'FCFA',
  XCD: '$',
  XDR: 'SDR',
  XOF: 'CFA',
  XPF: 'F',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWL: '$'
};

export const CURRENCY_LIST = CURRENCY_NAME_LIST.map(currency => ({
  slug: currency,
  name: `${currency} ${currencySymbols[currency] ? ` (${currencySymbols[currency]})` : ''}`,
  symbol: currencySymbols[currency] || '' // Assign the symbol if it exists, otherwise empty string
}));
export const getCurrencyBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = CURRENCY_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const DEFAULT_SALARY_TYPE = 'hourly';
export const SALARY_TYPE_LIST = [
  { name: t('hourly'), slug: 'hourly' },
  { name: t('daily'), slug: 'daily' },
  { name: t('weekly'), slug: 'weekly' },
  { name: t('bi-weekly'), slug: 'bi-weekly' },
  { name: t('semi-monthly'), slug: 'semi-monthly' },
  { name: t('monthly'), slug: 'monthly' },
  { name: t('quarterly'), slug: 'quarterly' },
  { name: t('annually'), slug: 'annually' }
];
export const flattenWorkFlowNodeListData = (data: any, parent_id = null) => {
  return data.reduce((acc: any, node: any) => {
    // Add current node
    acc.push({
      id: node.id,
      company: node.company,
      name: node.name,
      slug: node.slug,
      color_code: node.color_code,
      order_no: node.order_no,
      allows_edit: node.allows_edit,
      parent_id: parent_id
    });

    // Recursively add children if they exist
    if (node.children && node.children.length > 0) {
      acc.push(...flattenWorkFlowNodeListData(node.children, node.id));
    }

    return acc;
  }, []);
};
export const OWNERSHIP_TYPE_LIST = [
  { name: t('other'), slug: 'Other' },
  { name: t('private'), slug: 'Private' },
  { name: t('public'), slug: 'Public' },
  { name: t('subsidiary'), slug: 'Subsidiary' }
];
export const getOwnershipTypeBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = OWNERSHIP_TYPE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// account type...............
export const getAccountTypeObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// EmailSignature...............
export const getEmailSignatureObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// Email Template...............
export const getEmailTemplateObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name,
    ...element
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// lead stage type
export const LEAD_STAGE_TYPE_LIST = [
  { name: t('lead'), slug: LEAD_SLUG },
  { name: t('lead_development'), slug: LEAD_DEVELOPMENT_SLUG }
];
export const getLeadStageTypeBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = LEAD_STAGE_TYPE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// Lead Stage...............
export const getLeadStageObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name,
    color_code: element.color_code
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// Lead...............
export const getLeadObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.first_name + ' ' + element.last_name),
    ...element
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// Deal...............
export const getDealObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(
      element.lead.first_name + ' ' + element.lead.last_name
    ),
    ...element
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// Lead..Development.............
export const getLeadDevelopmentObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: capitalizeWord(element.first_name + ' ' + element.last_name),
    ...element
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// Account...............
export const getLeadAccountObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
export const SOURCE_TYPE_LIST = [
  { name: t('linkedIn'), slug: 'LinkedIn' },
  { name: t('zoomInfo'), slug: 'ZoomInfo' }
];
export const getSourceTypeBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = SOURCE_TYPE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// proposal
export const PROPOSAL_STAGE_LIST = [
  { name: t('Qualifying'), slug: 'qualifying' },
  { name: t('Internal Review'), slug: 'internal_review' },
  { name: t('Negotiation'), slug: 'negotiation' }
];
export const DEFAULT_REVENUE_PROBABLITY_PERC = 20;
export const DISCOUNT_TYPE_PERC_SLUG = 'percentage';
export const DISCOUNT_TYPE_LIST = [
  { name: t('perc'), slug: 'percentage' },
  { name: t('fixed'), slug: 'fixed' }
];
export const getDiscountTypeBySlug = (slug: any) => {
  let selectedSlug = null;
  const filteredData = DISCOUNT_TYPE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// Proposal Stage...............
export const getProposalStageObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name,
    color_code: element.color_code
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
// Deal Stage...............
export const getDealStageObj = (element: any, setDisabled = false) => {
  let obj = {
    id: element.id,
    name: element.name,
    color_code: element.color_code
  };
  if (setDisabled) {
    obj = { ...obj, ...{ isDisabled: element.status ? false : true } };
  }
  return obj;
};
