import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

const PlainLayout = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();

  return (
    <div className="container">
      <Row className="flex-center min-vh-100 py-5">
        <Col sm={10} md={8} lg={5} className={'col-xxl-4'}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

export default PlainLayout;
