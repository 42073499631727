import Avatar from 'components/base/Avatar';
import { useEffect, useState } from 'react';
import { Card, Dropdown, Form, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/72x72/57.webp';
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { loginRoutes, mainRoutes, userRoutes } from 'config/routing';
import { getAwsUrl } from 'helpers/AwsHelper';
import { t } from 'i18next';
import { logoutUser } from 'store/actions/AuthAction';
import { RootState } from 'store';
import {
  userCurrentRoleSelector,
  userProfileImageSelector,
  userProfileSelector,
  userRoleListSelector
} from 'components/_trackivo/selectors/authSelectors';
import { connect } from 'react-redux';
import { capitalizeWord, placeParamsToURL } from 'helpers/CommonHelper';
import { filterItems } from 'helpers/ArrayHelper';
import {
  hasUserAccessFunctionality,
  permissionSlugs
} from 'helpers/PermissionHelper';

const ProfileDropdownMenu = ({
  className,
  userProfileInfo,
  currentRole,
  userRoleList,
  profileImage,
  handleNavClick
}: {
  className?: string;
  userProfileInfo: any;
  currentRole: any;
  userRoleList: any;
  profileImage: any;
  handleNavClick?: any;
}) => {
  const navigate = useNavigate();
  const [navItems, setNavItems] = useState([
    {
      label: t('profile'),
      slug: 'profile',
      icon: 'user',
      pageLink: placeParamsToURL(mainRoutes.userProfile.path)
    }
    // {
    //   label: t('settings'),
    //   slug: 'settings',
    //   icon: 'settings',
    //   pageLink: placeParamsToURL(mainRoutes.userSettings.path)
    // }
    // {
    //   label: t('switch_role'),
    //   icon: 'user',
    //   pageLink: userRoutes.roleSelection.path
    // }
    // {
    //   label: 'Dashboard',
    //   icon: 'pie-chart'
    // },
    // {
    //   label: 'Posts & Activity',
    //   icon: 'lock'
    // },
    // {
    //   label: 'Settings & Privacy ',
    //   icon: 'settings'
    // },
    // {
    //   label: 'Help Center',
    //   icon: 'help-circle'
    // },
    // {
    //   label: 'Language',
    //   icon: 'globe'
    // }
  ]);
  useEffect(() => {
    if (userRoleList) {
      if (
        userRoleList.length >= 1 &&
        hasUserAccessFunctionality([
          permissionSlugs.meeting_setting_list,
          permissionSlugs.email_setting_list,
          permissionSlugs.signature_list
        ])
      ) {
        //
        const filterData = filterItems(navItems, 'slug', 'settings');
        if (filterData.length == 0) {
          navItems.push({
            label: t('settings'),
            slug: 'settings',
            icon: 'settings',
            pageLink: placeParamsToURL(mainRoutes.userSettings.path)
          });
        }
      }

      if (userRoleList.length > 1) {
        const filterData = filterItems(navItems, 'slug', 'switch_role');
        if (filterData.length == 0) {
          // setNavItems(prevState => [
          //   ...prevState,
          //   {
          //     label: t('switch_role'),
          //     icon: 'user',
          //     slug: 'switch_role',
          //     pageLink: userRoutes.roleSelection.path
          //   }
          // ]);
          navItems.push({
            label: t('switch_role'),
            icon: 'user',
            slug: 'switch_role',
            pageLink: placeParamsToURL(userRoutes.roleSelection.path)
          });
        }
      }
      setNavItems(navItems);
    }
  }, [userRoleList]);
  const confirmLogout = () => {
    // confirmation
    // confirmAlert(t("confirm_logout_msg"), () => {
    // confirmed yes
    logoutUser(() => navigate(placeParamsToURL(loginRoutes.signin.path)));
    // });
  };
  if (userProfileInfo && Object.keys(userProfileInfo).length > 0) {
    return (
      <Dropdown.Menu
        align="end"
        className={classNames(
          className,
          'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
        )}
      >
        <Card className="position-relative border-0">
          <Card.Body className="p-0">
            <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
              {profileImage && (
                <Avatar
                  /* src={
                  userProfileInfo.profile_pic
                    ? getAwsUrl(userProfileInfo.profile_pic)
                    : getAwsUrl(DEFAULT_AWS_IMAGE.profile)
                } */
                  src={getAwsUrl(profileImage)}
                  size="xl"
                />
              )}
              <h6 className="text-black">
                {capitalizeWord(
                  userProfileInfo.first_name + ' ' + userProfileInfo.last_name
                )}
              </h6>
              {currentRole ? (
                <span style={{ marginTop: '-9px' }}>{currentRole.name}</span>
              ) : (
                ''
              )}
            </div>
            {/* <div className="mb-3 mx-3">
              <Form.Control
                type="text"
                placeholder="Update your status"
                size="sm"
              />
            </div> */}
            <div style={{ height: '5rem' }}>
              <Scrollbar>
                <Nav className="nav flex-column mb-2 pb-1">
                  {navItems.map(item => (
                    <Nav.Item key={item.label}>
                      <Nav.Link
                        as={Link}
                        to={item.pageLink}
                        className="px-3"
                        onClick={() => (handleNavClick ? handleNavClick() : '')}
                      >
                        <FeatherIcon
                          icon={item.icon}
                          size={16}
                          className="me-2 text-900"
                        />
                        <span className="text-1000">{item.label}</span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Scrollbar>
            </div>
          </Card.Body>
          <Card.Footer className="p-0 border-top">
            <div className="px-3 my-2 mx-3">
              <a
                onClick={() => confirmLogout()}
                className="btn btn-phoenix-secondary d-flex flex-center w-100"
              >
                <FeatherIcon icon="log-out" className="me-2" size={16} />
                {t('signout')}
              </a>
            </div>
          </Card.Footer>
        </Card>
      </Dropdown.Menu>
    );
  } else {
    return null;
  }
};

// export default ProfileDropdownMenu;
const mapStateToProps = (state: RootState) => {
  return {
    userProfileInfo: userProfileSelector(state),
    currentRole: userCurrentRoleSelector(state),
    profileImage: userProfileImageSelector(state),
    userRoleList: userRoleListSelector(state)
  };
};
export default connect(mapStateToProps, {})(ProfileDropdownMenu);
